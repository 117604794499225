import * as React from 'react';
import { useNavigate } from "react-router-dom";

import Avatar from '@mui/material/Avatar';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

export function Services() {
    return (
        <div style={{ padding: "30px" }}>
            <Grid container spacing={{ xs: 4, md: 3 }} columns={{ xs: 2, sm: 8, md: 12 }}>
                <ServicesRender includeDetail={true} includeLinkToServices={false} />
            </Grid>
        </div>
    );
}

export function ServicesRender({includeDetail, includeLinkToServices}) {
    return (
        <>
            {Service(
                "Birth Photography",
                "Birth is such a powerful and unique experience, allow me to capture your birth story.",
                "/images/services/birth-min.jpg",
                [
                    "Free 30 Minute Online Consulation",
                    "1 in person meetup to create connection and chat birth plan and photography preferences",
                    "Complimentary maternity photoshoot",
                    "On call from 38 weeks until baby is born",
                    "Full birth story captured and 1 hour postpartum",
                    "Password protected digital gallery of your birth story"
                ],
                "899",
                includeDetail,
                includeLinkToServices
            )}
            {Service(
                "Newborn Photography",
                "Timeless images to remember those little hands and features, they change in a blink of an eye.",
                "/images/services/newborn-min.jpg",
                [
                    "1 Hour photography session",
                    "Location of your choice",
                    "10+ High res digital images edited in my signature style",
                    "Option to use my props",
                    "Password protected digital online gallery"
                ],
                "85",
                includeDetail,
                includeLinkToServices
            )}
            {Service(
                "Cake Smash Photography",
                "What’s more fun than babies and cake?! Cake smash sessions for 1st birthdays or 1/2 birthdays.",
                "/images/services/cakesmash-min.jpg",
                [
                    "30 minute photography session",
                    "Location of your choice",
                    "5 High res digital images edited in my signature style",
                    "Option to use my props",
                    "Password protected digital online gallery",
                    "If providing your own cake the session will cost £50",
                    "I can provide a cake made by a local trusted baker and the session will cost £85"
                ],
                "50-85",
                includeDetail,
                includeLinkToServices
            )}
            {Service(
                "Maternity Photography",
                "Growing a human is amazing, this is your sign to document your pregnancy.",
                "/images/services/maternity-min.jpg",
                [
                    "30 minute photography session",
                    "Location of your choice",
                    "10+ High res digital images edited in my signature style",
                    "Password protected digital online gallery"
                ],
                "85",
                includeDetail,
                includeLinkToServices
            )}
            {Service("Family Photography",
                "Documenting the connection and candid moments of your family is a passion of mine.",
                "/images/services/family-min.jpg",
                [
                    "30 minute photography session",
                    "Location of your choice",
                    "10+ High res digital images edited in my signature style",
                    "Password protected digital online gallery"
                ],
                "85",
                includeDetail,
                includeLinkToServices
            )}
            {Service(
                "Seasonal Mini Photography",
                "Christmas, Halloween, Mother’s Day or a special occasion, these mini sessions are perfect for your celebration.",
                "/images/services/seasonal-min.jpg",
                [
                    "30 minute photography session",
                    "Location of your choice",
                    "5 High res digital images edited in my signature style",
                    "Password protected digital online gallery"
                ],
                "50",
                includeDetail,
                includeLinkToServices
            )}
        </>
    );
};

const ServiceItem = styled(Paper)(({ theme, includeDetail }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    minHeight: includeDetail ? "750px" : "320px",
    minWidth: "150px"
}));

const Service = (title, description, image, whatsIncluded, cost, includeDetail, includeLinkToServices) => {
    const navigate = useNavigate();
    return (
        <Grid item xs={2} sm={4} md={4}>
            <ServiceItem
                includeDetail={includeDetail}
                onClick={() => {
                  navigate('/services', { replace: true });
                }}
            >
                <Typography variant="h6" style={{ textAlign: "center", fontWeight: "bold", height: "70px" }}>
                    {title}
                </Typography>
                <Avatar
                    sx={{ width: 150, height: 150, margin: 'auto', marginBottom: '40px' }}
                    src={image}
                />
                <Typography variant="paragraph" style={{ textAlign: "center", fontWeight: 'bold' }}>
                    {description}
                </Typography>
                {
                    includeDetail === true ? (
                        <>
                            <List style={{ minHeight: '380px' }}>
                                {whatsIncluded.map(included => {
                                    return (
                                        <ListItem>
                                            <ListItemText
                                                style={{ textAlign: "center" }}
                                                primary={included}
                                            />
                                        </ListItem>
                                    );
                                })}
                            </List>
                            <Typography variant="h4" style={{ textAlign: "center" }}>
                                £{cost}
                            </Typography>
                        </>
                    ) : null
                }

            </ServiceItem>
        </Grid>
    );
}