import * as React from 'react';
import { useNavigate } from "react-router-dom";

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';

import MenuIcon from '@mui/icons-material/Menu';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';

const pages = [
  { 
    name: 'Home',
    link: '/'
  },
  { 
    name: 'About Me',
    link: '/about'
  },
  { 
    name: 'Services',
    link: '/services'
  },
  // { 
  //   name: 'Portfolio',
  //   link: '/portfolio'
  // },
  { 
    name: 'Bookings',
    link: '/bookings'
  },
  { 
    name: 'Contact',
    link: '/contact'
  }
];

function ResponsiveAppBar() {

  const navigate = useNavigate();

  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <AppBar position="static" style={{ height: "150px" }} sx={{ color: 'text.primary' }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <img
            src="/logo.jpg"
            alt="Logo"
            height="145"
            width="190"
            onClick={() => {
              navigate('/', { replace: true });
            }}
          />
          <Box sx={{ justifyContent: "center", flexGrow: 0.5, display: { xs: 'none', md: 'flex' }, color: 'text.primary' }}>
            {pages.map((page) => (
              <Button
                key={page.name}
                onClick={() => {
                  navigate(page.link, { replace: true });
                  handleCloseNavMenu();
                }}
                sx={{ my: 2, color: 'text.primary', display: 'block' }}
              >
                {page.name}
              </Button>
            ))}
          </Box>
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
            <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
                color: 'text.primary'
              }}
            >
              {pages.map((page) => (
                <MenuItem
                  key={page.name}
                  onClick={() => {
                    navigate(page.link, { replace: true });
                    handleCloseNavMenu();
                  }}
                >
                  <Typography textAlign="center">{page.name}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <Box sx={{ justifyContent: "flex-end", flexGrow: 1, display: "flex" }}>
            <IconButton
              size="large"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={() => {
                window.open('https://www.facebook.com/share/CmaDg424HZixtpVr/?mibextid=LQQJ4d','_blank')
              }}
              color="inherit"
            >
              <FacebookIcon />
            </IconButton>
            <IconButton
              size="large"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={() => {
                window.open('https://www.instagram.com/juniperplumphotography','_blank')
              }}
              color="inherit"
            >
              <InstagramIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default ResponsiveAppBar;
