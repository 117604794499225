import * as React from 'react';
import { useNavigate } from "react-router-dom";

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
  
function About() {

    const navigate = useNavigate();

    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
        >
            <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                    <Avatar
                        sx={{ width: 250, height: 250, margin: 'auto', marginBottom: '40px', marginTop: '40px' }}
                        src={"/images/about/self-min.jpg"}
                    />
                    <Box textAlign='center'>
                        <Button
                            variant='contained'
                            onClick={() => {
                                navigate('/contact', { replace: true });
                            }}
                        >
                            Get in touch
                        </Button>
                    </Box>
                </Grid>
                <Grid item xs={12} md={8} styling={{ padding: '5px'}}>
                    <Typography style={{ marginTop: '40px', marginBottom: '20px', margin: '25px', textAlign: "justify" }}>
                        Hello my name is Demi and I own Juniper Plum Photography. 
                    </Typography>
                    <Typography style={{ marginBottom: '20px', margin: '25px', textAlign: "justify" }}>
                        I specialise in newborn, birth, maternity and family photography. I love what I do and feel privileged that you have considered me to capture these previous moments in your life.
                    </Typography>
                    <Typography style={{ marginBottom: '20px', margin: '25px', textAlign: "justify" }}>
                        I have always had a passion for photography which only grew after my birth experience with my daughter Fleur. I knew then I wanted to be able to photograph the beauty of pregnancy to parenthood. I pride myself on taking emotive, natural, timeless images that capture those raw feelings. 
                    </Typography>
                    <Typography style={{ marginBottom: '20px', margin: '25px', textAlign: "justify" }}>
                        I am based along the Fylde Coast and cover: Lytham, St. Anne’s, Warton, Freckleton, Kirkham, Wrea Green, Thornton & Poulton.
                    </Typography>
                    <Typography style={{ marginBottom: '20px', margin: '25px', textAlign: "justify" }}>
                        I support every type of birth and believe it deserved to be captured whether it’s a home birth or a hospital birth. Each story is different and I aim to photograph all of the details of your birth experience. I approach each birthing space with respect and I am here to work around you and not interfere with your birthing process. 
                        Whether it’s pregnancy, birth or beyond invite me to capture these special moments with your family. I look forward to hearing from you. If you have any further questions please pop me a message, you can find more details on how to contact me by the contact page. 
                    </Typography>
                    <Typography style={{ marginBottom: '40px', margin: '25px', textAlign: "justify" }}>
                        Demi x
                    </Typography>
                </Grid>
            </Grid>
        </Box>
    );
}

export default About;