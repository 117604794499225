import './bookings.css';

function Bookings() {
  return (
    <div>
      <iframe
        title="bookings-iframe"
        id="bookings-iframe"
        src="https://calendar.google.com/calendar/u/0/appointments/schedules/AcZssZ3U14acBSiLcZhnvreNPBTtEpMokR1C3vKzpmSrUATCxa4ku38XiVaJ1AzDaaoRkzDFvciUhAWM?gv=true"
        style={{
          width: "calc(100vw - 20px)",
        }}
        scrolling="no"
      />
    </div>
  );
}

export default Bookings;
