import { useBlazeSlider } from 'react-blaze-slider';
import 'blaze-slider/dist/blaze.css';

import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import { ServicesRender } from '../Services/Services';

function Home () {
    
    const ref = new useBlazeSlider({
        all: {
            autoplayInterval: 2000,
            transitionDuration: 300,
            slidesToShow: 2
        },
        "(max-width: 900px)": {
          slidesToShow: 2
        },
        "(max-width: 500px)": {
          slidesToShow: 1
        }
    });

    return (
        <div style={{ padding: "30px" }}>
            <div className="blaze-slider" ref={ref}>
                <div className="blaze-container">
                    <div className="blaze-track-container">
                        <div className="blaze-track">
                            <img
                                alt=""
                                src="/images/homepage/1-min.jpg"
                            />
                            <img
                                alt=""
                                src="/images/homepage/3-min.jpg"
                            />
                            <img
                                alt=""
                                src="/images/homepage/4-min.jpg"
                            />
                            <img
                                alt=""
                                src="/images/homepage/5-min.jpg"
                            />
                            <img
                                alt=""
                                src="/images/homepage/2-min.jpg"
                            />
                            <img
                                alt=""
                                src="/images/homepage/8-min.jpg"
                            />
                            <img
                                alt=""
                                src="/images/homepage/6-min.jpg"
                            />
                            <img
                                alt=""
                                src="/images/homepage/7-min.jpg"
                            />
                        </div>
                    </div>
                    <button
                        class="blaze-prev"
                        style={{
                            float: "left",
                            alignItems: "center",
                            margin: 0,
                            position: "absolute",
                            top: "50%",
                            transform: "translateY(-50%)",
                            opacity: 0.5,
                        }}
                    >
                        <IconButton
                            size="large"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            color="inherit"
                        >
                            <ArrowBackIcon style={{ backgroundColor: "none", color: "black", fontSize: "20px" }} />
                        </IconButton>
                    </button>
                    <button
                        class="blaze-next"
                        style={{
                            float: "right",
                            alignItems: "center",
                            margin: 0,
                            position: "absolute",
                            top: "50%",
                            left: "calc(100vw - 112px)",
                            transform: "translate(-50%, -50%)",
                            opacity: 0.5,
                        }}
                    >
                        <IconButton
                            size="large"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            color="inherit"
                        >
                            <ArrowForwardIcon style={{ backgroundColor: "none", color: "black", fontSize: "20px" }} />
                        </IconButton>
                    </button>
                </div>
            </div>

            <div style={{ marginTop: "50px", marginBottom: "50px" }}>
                <Typography variant="h3" style={{ textAlign: "center", fontFamily: 'Georgia, serif' }}>
                    Welcome to Juniper Plum Photography
                </Typography>
                <br/>
                <Typography variant="h6" style={{ textAlign: "center" }}>
                    Emotive, natural images capturing those special milestones from pregnancy to parenthood. 
                </Typography>
                <br/>
                <Typography variant="h6" style={{ textAlign: "center" }}>
                    My name is Demi, your trusted birth, maternity, newborn and family photographer located on the Fylde Coast. I love what I do and I feel privileged you have chosen me to capture your birth story and moments with your family. 
                </Typography>
            </div>

            <Grid container spacing={{ xs: 4, md: 3 }} columns={{ xs: 2, sm: 8, md: 12 }}>
                <ServicesRender includeDetail={false} includeLinkToServices={true} />
            </Grid>

        </div>
    );

};

export default Home;
