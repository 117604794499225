import {
  Route,
  Routes,
  BrowserRouter
} from "react-router-dom";

import { createTheme, ThemeProvider } from '@mui/material/styles';
import Paper from '@mui/material/Paper';

import NavBar from './Components/NavBar/NavBar';
import Home from './Components/Home/Home';
import Bookings from './Components/Bookings/Bookings';
import About from './Components/About/About';
import Contact from './Components/Contact/Contact';
import Portfolio from './Components/Portfolio/Portfolio';
import { Services } from './Components/Services/Services';

const theme = createTheme({
  palette: {
    primary: {
      main: "#738383",
    },
    text: {
      primary: "#000000"
    },
    secondary: {
      main: "#738383",
    },
  },
});

function App() {
  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <NavBar />
        <Paper
          sx={{ backgroundColor: "primary.main" }}
          elevation={10}
          style={{
            width: "calc(100vw - 20px)",
            margin: "10px"
          }}
        >
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="about" element={<About />} />
            <Route path="contact" element={<Contact />} />
            <Route path="portfolio" element={<Portfolio />} />
            <Route path="services" element={<Services />} />
            <Route path="bookings" element={<Bookings />} />
          </Routes>
        </Paper>
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;
