import * as React from 'react';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';

import CallIcon from '@mui/icons-material/Call';
import EmailIcon from '@mui/icons-material/Email';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';

function Contact() {
    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
        >
            <List sx={{ width: '100%', maxWidth: 360 }}>
                <ListItem component="a" href="tel:07738057648" style={{ color: 'black' }}>
                    <ListItemAvatar>
                    <Avatar>
                        <CallIcon />
                    </Avatar>
                    </ListItemAvatar>
                    <ListItemText component="a" primary="Phone" href="tel:07738057648" secondary="07738057648" />
                </ListItem>
                <ListItem component="a" href="mailto:juniperplumphotography@gmail.com" style={{ color: 'black' }}>
                    <ListItemAvatar>
                    <Avatar>
                        <EmailIcon />
                    </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary="Email" secondary="juniperplumphotography@gmail.com" />
                </ListItem>
                <ListItem component="a" target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/juniperplumphotography" style={{ color: 'black' }}>
                    <ListItemAvatar>
                    <Avatar>
                        <InstagramIcon />
                    </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary="Instagram" />
                </ListItem>
                <ListItem component="a" target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/share/CmaDg424HZixtpVr/?mibextid=LQQJ4d" style={{ color: 'black' }}>
                    <ListItemAvatar>
                    <Avatar>
                        <FacebookIcon />
                    </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary="Facebook" />
                </ListItem>
            </List>
        </Box>
    );
}

export default Contact;